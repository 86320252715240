import React, { useState, useEffect } from "react";
import ky from "ky";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Field,
  Flex,
  Heading,
  Label,
  Select,
  Spinner,
  Text,
  Textarea,
  Link as ThemeUiLink,
} from "theme-ui";
import { format } from "date-fns/esm";
import { logHttpErrorDetails } from "../lib/utils";

type Props = {
  data: {
    markdownRemark: any;
  };
  location: object;
};

const MakeAppointmentPage = ({ data, location }: Props) => {
  const { frontmatter } = data.markdownRemark;

  const [store, setStore] = useState<string>("default");
  const [date, setDate] = useState<Date>(new Date());
  const [appointType, setAppointType] = useState<
    "beratung" | "service" | "reparatur" | "anpassung" | "hoertest" | "gehoerschutz"
  >("beratung");
  const [isCustomer, setIsCustomer] = useState<"todo" | "yes" | "no">("todo");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState<"todo" | "mann" | "frau" | "divers">("todo");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [appointState, setAppointState] = useState<"todo" | "query" | "success" | "failure">(
    "todo"
  );
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    setStore(location.state.store);
    setDate(location.state.date);
    setAppointType(location.state.appointType);
  }, []);

  const makeAppointment = async () => {
    if (!privacyChecked) {
      setError("Bitte akzeptieren Sie die Datenschutzerklärung.");
      return;
    }
    if (isCustomer == "todo") {
      setError("Bitte wählen Sie aus ob Sie bereits unser Kunde sind.");
      return;
    }
    if (!email.includes("@")) {
      setError("Bitte geben Sie eine gültige Email an.");
      return;
    }
    setError(null);
    setAppointState("query");

    try {
      await ky.post(
        `${process.env.GATSBY_CALENDAR_API_URL}/${process.env.BACKEND_COMPANY_ID}/make_appointment`,
        {
          json: {
            store: store,
            date: (date?.getTime() || 0) / 1000,
            appointType: appointType,
            isCustomer: isCustomer == "yes" ? true : false,
            firstname: firstname,
            lastname: lastname,
            gender: gender,
            tel: tel,
            email: email,
            message: message,
          },
        }
      );
      setAppointState("success");
    } catch (error) {
      console.log(error);
      logHttpErrorDetails(error);
      setError("Verbindungsfehler zum Online Kalender. Bitte versuchen Sie es später nochmal.");
      setAppointState("failure");
    }
  };

  return (
    <Layout seo={{ title: frontmatter.title, noindex: true }}>
      <Container my={5} variant="ctiny">
        <Heading as="h1" variant="styles.h2" mb={4}>
          {frontmatter.title}
        </Heading>
        {appointState != "success" && (
          <Box as="form" onSubmit={(e) => e.preventDefault()}>
            <Text>
              Termin am {format(date, "dd.MM.yyyy HH:mm")} in der Filiale{" "}
              {store.charAt(0).toUpperCase() + store.slice(1)} ausmachen.
            </Text>
            <Box my={2}>
              <Label htmlFor="appointtype">Termintyp</Label>
              <Select
                name="appointtype"
                id="appointtype"
                value={appointType}
                onChange={(e) => setAppointType(e.target.value as any)}
                disabled={true}
              >
                <option value="beratung">Beratung (90m)</option>
                <option value="service">Service (30m)</option>
                <option value="reparatur">Reparatur (30m)</option>
                <option value="anpassung">Neue Hörgeräte (60m)</option>
                <option value="hoertest">Hörtest (60m)</option>
                <option value="gehoerschutz">Gehörschutz (30m)</option>
              </Select>
            </Box>
            <Box my={2}>
              <Label htmlFor="isCustomer">Sind Sie bereits unser Kunde?</Label>
              <Select
                name="isCustomer"
                id="isCustomer"
                value={isCustomer}
                onChange={(e) => setIsCustomer(e.target.value as any)}
              >
                <option value="todo"></option>
                <option value="yes">Ja</option>
                <option value="no">Nein</option>
              </Select>
            </Box>
            <Box my={2}>
              <Label htmlFor="gender">Anrede</Label>
              <Select
                name="gender"
                id="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value as any)}
              >
                <option value="todo"></option>
                <option value="mann">Herr</option>
                <option value="frau">Frau</option>
                <option value="divers">Divers</option>
              </Select>
            </Box>
            <Field
              label="Vorname"
              name="firstname"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
            />
            <Field
              label="Nachname"
              name="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              required
            />
            <Field
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Field
              label="Telefon"
              name="tel"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              required
            />
            <Box>
              <Label htmlFor="message">Nachricht (optional)</Label>
              <Textarea
                name="message"
                id="message"
                rows={6}
                mb={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required={false}
                sx={{}}
              />
            </Box>
            <Box mt={4} mb={3}>
              <Label>
                <Checkbox
                  value={privacyChecked ? 1 : 0}
                  onChange={(e) => setPrivacyChecked(!!e.target.value)}
                />
                Ich stimme der{" "}
                <ThemeUiLink href="/datenschutz" target="_blank" mx={1}>
                  Datenschutzerklärung
                </ThemeUiLink>{" "}
                zum Online-Terminkalender zu.
              </Label>
            </Box>
            <Flex>
              <Button
                variant="cta"
                sx={{ flex: "1" }}
                onClick={makeAppointment}
                disabled={appointState == "query"}
              >
                {appointState == "query" ? (
                  <Spinner size={32} variant="styles.spinner" />
                ) : (
                  "Termin buchen"
                )}
              </Button>
            </Flex>
            {appointState == "failure" && (
              <Box mt={2}>
                <Text variant="smallText">{error}</Text>
              </Box>
            )}
          </Box>
        )}
        {appointState == "success" && (
          <Text>
            Termin erfolgreich gebucht! Wir schicken Ihnen in Kürze eine Bestätigungsemail an{" "}
            {email}.
          </Text>
        )}
      </Container>
    </Layout>
  );
};

export default MakeAppointmentPage;

export const pageQuery = graphql`
  query MakeAppointmentPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "make-appointment-page" } }) {
      id
      frontmatter {
        title
      }
    }
  }
`;
